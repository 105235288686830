import requestService from "../requestService";
import store from '@/store';

export default {
	async createBus(form) {
		const response = await requestService.post(`/cp_dash/bus`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async updateBus(uuid, form) {
		const response = await requestService.post(`/cp_dash/bus/${uuid}`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async changeBusStatus(id) {
		const response = await requestService.post(`/cp_dash/bus/change_status/${id}`, {}, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getBuses(manufacturer, page) {
		const response = await requestService.get(`/v1/bus${manufacturer !== 0 && manufacturer !== '' && manufacturer !== null && manufacturer !== undefined ? `/manufacturer/${manufacturer}` : ''}?page=${page}`, {
			headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
		})
		return response?.data
	},
	async getBusesForAdmin(page, search, limit) {
		const response = await requestService.get(`/cp_dash/bus?page=${page}&search=${search}&limit=${limit || limit == 0 ? limit : 10}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getAllBuses() {
		const response = await requestService.get(`/cp_dash/bus?limit=0`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getBusesForAdminByStatus(status, page) {
		const response = await requestService.get(`/cp_dash/bus/list/${status || ''}?page=${page}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async searchBus(search) {
		const response = await requestService.get(`/cp_dash/bus/search/${search}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getBusesForMain() {
		const response = await requestService.get(`/v1/bus/carousel/6`, {
			headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
		})
		return response?.data
	},
	async getBus(id) {
		const response = await requestService.get(`/cp_dash/bus/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getBusManufacturer() {
		const response = await requestService.get(`/v1/bus/manufacturer`,)
		return response?.data
	},
	async getBusByQrCode(id) {
		const response = await requestService.get(`/v1/bus/${id}`)
		return response?.data
	},
	async generateQrCode(form) {
		const response = await requestService.post(`/generate_qr`, form)
		return response?.data
	}
}