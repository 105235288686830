<template>
  <div class="busBackground">
    <v-row no-gutters align="center">
      <v-col class="px-0 py-0" cols="3" style="position: relative">
        <v-row
          no-gutters
          align="center"
          justify="space-between"
          style="
            position: absolute;
            width: 100%;
            margin-top: 90px;
            padding: 0px 10px;
          "
        >
          <div
            class="iconBackgroundBus"
            :class="`swiper-button-prev-bus swiper-button-prev-bus-${bus.id}`"
            style="z-index: 10"
            slot="button-prev"
          >
            <img
              src="@/assets/img/iconsSvg/swiperRightIcon.svg"
              alt="right icon"
              style="transform: rotate(180deg)"
            />
          </div>
          <div
            style="
              box-shadow: 12px 0px 20px 0px rgba(0, 0, 0, 0.1);
              z-index: 10;
            "
            class="iconBackgroundBus"
            :class="`swiper-button-next-bus swiper-button-next-bus-${bus.id}`"
            slot="button-next"
          >
            <img
              src="@/assets/img/iconsSvg/swiperRightIcon.svg"
              alt="right icon"
            />
          </div>
        </v-row>
        <swiper
          ref="mySwiper"
          :options="getSwiperOptions(bus.id)"
          class="busCardImgSwiper"
        >
          <swiper-slide
            v-for="image in bus.images"
            :key="image.id"
            :cssMode="true"
            :navigation="true"
            :pagination="false"
            :mousewheel="true"
            :keyboard="true"
            :modules="getSwiperOptions?.modules"
          >
            <img
              :src="image.path"
              alt="bus"
              width="100%"
              height="235px"
              style="border-radius: 10px; object-fit: cover"
            />
          </swiper-slide>
        </swiper>
      </v-col>
      <v-col
        class="py-0"
        cols="8"
        style="
          padding-left: 40px;
          padding-right: 40px;
          border-right: 1px solid #eee;
        "
      >
        <p class="busModel">{{ bus.manufacturer }} {{ bus.model }}</p>
        <v-row no-gutters align="center" style="margin: 20px 0px 8px 0px">
          <div style="display: flex; align-items: center">
            <span style="margin-bottom: 12px">Рік випуску</span>
            <div class="itemValueBox">{{ bus.year }}</div>
          </div>
          <div style="display: flex; align-items: center">
            <span style="margin-bottom: 12px">Колір</span>
            <div class="itemValueBox">
              {{
                bus?.translations.find((translate) => translate.lang == "ua")
                  ?.color
              }}
            </div>
          </div>
          <div style="display: flex; align-items: center">
            <span style="margin-bottom: 12px">Кількість місць</span>
            <div class="itemValueBox">{{ bus.seats }}</div>
          </div>
          <div style="display: flex; align-items: center">
            <span style="margin-bottom: 12px">Поверховість автобуса</span>
            <div class="itemValueBox">
              {{
                bus?.translations.find((translate) => translate.lang == "ua")
                  ?.floors
              }}
            </div>
          </div>
          <div style="display: flex; align-items: center">
            <span style="margin-bottom: 12px">Номер</span>
            <div class="itemValueBox">{{ bus.government_number }}</div>
          </div>
        </v-row>
        <p class="busSectionName">Додаткові сервіси</p>
        <v-row no-gutters align="center" v-if="bus.services">
          <div
            class="optionBox"
            v-for="service in bus?.services"
            :key="service.id"
          >
            {{
              service.translations.find((translate) => translate.lang == "ua")
                ?.name
            }}
          </div>
        </v-row>
      </v-col>
      <v-col class="px-0 py-0" cols="1">
        <v-row no-gutters justify="center">
          <div>
            <div class="iconBackground" @click="$emit('changeStatus', bus.id)">
              <div class="archiveIcon" v-if="bus.status == 'Active'" />
              <v-icon v-else color="#4B5262" class="mdi-refresh"
                >mdi-refresh</v-icon
              >
            </div>
            <div
              class="iconBackground"
              style="margin-top: 12px"
              @click="
                $router.push({ name: 'editAutopark', params: { id: bus.id } })
              "
            >
              <div class="editIcon" />
            </div>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import swiperOptionMixin from "../../../mixins/swiperOptionMixin";
export default {
  mixins: [swiperOptionMixin],
  data: () => ({
    swiperOptions: {
      spaceBetween: 0,
      navigation: {
        nextEl: `.swiper-button-next-bus`,
        prevEl: `.swiper-button-prev-bus`,
      },
    },
  }),
  props: {
    bus: { require: true },
  },
  methods: {
    getSwiperOptions(busId) {
      return {
        spaceBetween: 40,
        navigation: {
          nextEl: `.swiper-button-next-bus-${busId}`,
          prevEl: `.swiper-button-prev-bus-${busId}`,
        },
      };
    },
  },
};
</script>

<style scoped>
.busBackground {
  border-radius: 10px;
  background: #fff;
  margin-top: 20px;
  padding: 20px;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.busModel {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0px;
}
.busSectionName {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;
}
.itemValueBox {
  color: #144fa9;
  padding: 4px 8px;
  border-radius: 10px;
  background: rgba(20, 79, 169, 0.1);
  margin: 0px 12px 12px 12px;
}
.optionBox {
  color: #1b1b1b;
  padding: 4px 8px;
  border-radius: 10px;
  background: rgba(20, 79, 169, 0.1);
  margin-right: 20px;
  margin-bottom: 12px;
}
.iconBackgroundBus {
  display: grid;
  place-items: center;
  width: 32px;
  height: 32px;
  border-radius: 30px;
  background: #fafafa;
  box-shadow: -12px 0px 20px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  user-select: none;
}
.iconBackground:hover .mdi-refresh:hover {
  color: #fff !important;
}
</style>