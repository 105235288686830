<template>
  <v-row
    no-gutters
    align="center"
    style="margin-top: 20px"
    v-if="quantityPage > 1"
  >
    <v-col
      cols="1"
      class="px-0 py-0"
      style="cursor: pointer; user-select: none"
      @click="previousPage"
    >
      <v-row no-gutters align="center">
        <img
          src="@/assets/img/iconsSvg/paginationArrow.svg"
          style="margin-right: 8px"
        />
        <span
          style="
            color: #989898;
            font-family: 'MacPaw Fixel';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          "
          >Назад</span
        >
      </v-row>
    </v-col>
    <v-col cols="10" class="px-0 py-0">
      <v-pagination
        :length="quantityPage"
        v-model="page"
        color="#144FA9"
        :next-icon="null"
        :prev-icon="null"
      ></v-pagination>
    </v-col>
    <v-col
      cols="1"
      class="px-0 py-0"
      style="cursor: pointer; user-select: none"
      @click="nextPage"
    >
      <v-row no-gutters align="center" justify="end">
        <span
          style="
            color: #989898;
            font-family: 'MacPaw Fixel';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          "
          >Вперед</span
        >
        <img
          src="@/assets/img/iconsSvg/paginationArrow.svg"
          style="margin-left: 8px; transform: rotate(180deg)"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    localPage: 1,
  }),
  props: {
    page: {
      require: true,
    },
    quantityPage: {
      require: true,
    },
  },
  mounted() {
    this.localPage = this.page;
  },
  methods: {
    previousPage() {
      if (this.localPage > 1) {
        this.localPage--;
      }
    },
    nextPage() {
      if (this.localPage < this.quantityPage) {
        this.localPage++;
      }
    },
  },
  watch: {
    localPage(newValue) {
      this.$emit("changePage", newValue);
    },
    page(newValue) {
      this.localPage = newValue;
    },
  },
};
</script>

<style>
</style>