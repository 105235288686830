<template>
  <v-text-field
    outlined
    dense
    :label="label"
    :placeholder="placeholder"
    hide-details
    height="48px"
    style="border-radius: 10px"
    prepend-inner-icon="mdi-magnify"
    v-model="search"
    background-color="#FFF"
    color="#E2E2E2"
    autocomplete="new-username"
  >
    <template v-slot:prepend-inner>
      <v-row no-gutters align="center" style="margin-top: 4px">
        <v-icon>mdi-magnify</v-icon>
        <div
          style="width: 1px; height: 20px; background: #c1c1c1; margin: 0px 8px"
        ></div>
      </v-row>
    </template>
  </v-text-field>
</template>

<script>
export default {
  data: () => ({
    search: "",
    searchTimeout: null,
  }),
  props: {
    label: {
      require: false,
    },
    placeholder: {
      require: false,
    },
  },
  watch: {
    search: {
      handler() {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          if (this.search !== undefined && this.search !== null) {
            this.$emit("search", this.search);
          }
        }, 500);
      },
    },
  },
};
</script>

<style>
.v-text-field--outlined.v-input--dense .v-label--active {
  margin-left: 2px !important;
  color: #1b1b1b !important;
}
.v-text-field--outlined.v-input--dense .v-label {
  top: 14px!important;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>