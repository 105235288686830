<template>
  <v-col class="px-0 py-0">
    <v-expand-transition>
      <v-col class="px-0 py-0" v-if="$route.name == 'autoparkComponent'">
        <v-row no-gutters align="center" style="margin-top: 20px">
          <v-col cols="4" class="px-0 py-0">
            <searchField
              label="Пошук автобуса"
              placeholder="Введіть тут.."
              @search="searchBus"
            ></searchField>
          </v-col>
          <v-row no-gutters justify="end">
            <submit-button
              style="height: 48px !important; width: 220px"
              @click="$router.push({ name: 'createAutopark' })"
            >
              <div
                class="adminPlusIcon"
                style="margin-right: 8px; background-color: #fafafa"
              />
              Додати автобус</submit-button
            >
          </v-row>
        </v-row>
        <v-row no-gutters align="center" style="margin-top: 20px">
          <div
            class="statusBox"
            :class="status == 'Active' ? 'activeStatusBox' : ''"
            @click="status = 'Active'"
          >
            Активні
          </div>
          <div
            class="statusBox"
            :class="status == 'Archive' ? 'activeStatusBox' : ''"
            @click="status = 'Archive'"
          >
            Архівовані
          </div>
        </v-row>
        <Loader v-if="showLoader" />
        <bus-card
          v-else
          v-for="bus in busList"
          :key="bus.id"
          :bus="bus"
          @changeStatus="changeStatus"
          @editBus="showEditBusPage"
        />
        <pagination
          :page="page"
          :quantityPage="quantityPage"
          @changePage="changePage"
        />
      </v-col>
    </v-expand-transition>
    <v-expand-transition>
      <!-- <create-new-bus
        v-if="isShowCreateNewBus"
        :busForEdit="busForEdit"
        :isEdit="isEdit"
        @back="isShowCreateNewBus = false"
        @successCreate="
          (showLoader = true), getBuses(), (isShowCreateNewBus = false)
        "
      /> -->
      <router-view v-if="$route.name !== 'autoparkComponent'" />
    </v-expand-transition>
  </v-col>
</template>

<script>
import submitButton from "../../UI/Buttons/submitButton.vue";
import busCard from "@/components/Admin/Autopark/busCard";
//import CreateNewBus from "./createNewBus.vue";
import searchField from "./../../UI/Fields/searchField.vue";
import busService from "../../../requests/Bus/busService";
import Loader from "../../UI/Loader.vue";
import Pagination from "../../UI/pagination.vue";
export default {
  components: {
    submitButton,
    busCard, //CreateNewBus,
    searchField,
    Loader,
    Pagination,
  },
  data: () => ({
    status: "Active",
    search: "",
    isShowCreateNewBus: false,
    busList: false,
    isEdit: false,
    showLoader: true,
    busForEdit: {},
    page: 1,
    quantityPage: 0,
  }),
  mounted() {
    this.searchBus();
  },
  methods: {
    changePage(page) {
      this.page = page;
      this.showLoader = true;
      this.getBuses();
    },
    async getBuses() {
      await busService.getBusesForAdmin(this.page, this.search).then((res) => {
        if (res.status == "Success") {
          this.busList = res.data;
          this.quantityPage = res?.pagination?.meta?.last_page;
          this.showLoader = false;
        }
      });
    },
    async searchBus(search) {
      this.showLoader = true;
      if (search !== "" && search !== undefined && search !== null) {
        this.search = `[status[eq]:${this.status};all[like]:${search}]`;
      } else {
        this.search = `[status[eq]:${this.status};]`;
      }
      this.getBuses();
    },
    showEditBusPage(bus) {
      this.busForEdit = bus;
      this.isEdit = true;
      this.isShowCreateNewBus = true;
    },
    async changeStatus(id) {
      await busService.changeBusStatus(id).then((res) => {
        if (res.status == "Success") {
          this.showLoader = true;
          this.getBuses();
        }
      });
    },
  },
  watch: {
    isShowCreateNewBus: {
      handler() {
        this.$vuetify.goTo(0, 0);
      },
    },
    "$route.path": {
      handler() {
        if (this.$route.name == "autoparkComponent") {
          this.showLoader = true;
          this.getBuses();
        }
      },
    },
    status: {
      handler() {
        this.searchBus();
      },
    },
  },
};
</script>

<style>
</style>